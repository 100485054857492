.HeadOtp{
    text-align: -webkit-center;
   
    margin-top: 40px;
}
.OTP{
    background-color: #9f249614;
    width:400px;
    border: 1px solid rgba(0, 0, 0, 0.322);
    border-radius: 5px;

}
.reSend{
    text-align: center;
    margin-top: 20px;
    border-radius: 5px;
    border: none;
    margin-left: 15px;
    color: rgb(255, 255, 255);
    background-image:linear-gradient(#da18d0,#a200ff) !important;
    margin-top: 20px;
    padding: 5px 15px;
    font-size: 15px;
  
}
.OtpInput{
    display: flow-root !important;
    padding-top: 30px;
    padding-bottom: 20px;
   
}
.buttonVerify{
    margin-bottom: 30px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
    margin-left: 15px;
    color: rgb(255, 255, 255);
    background-image:linear-gradient(#da18d0,#a200ff);
    margin-top: 20px;
    padding: 5px 15px;
    font-size: 15px;
   

}
.burg{
    border-radius: 5px;
    border: none;
    margin-left: 15px;
    color: rgb(255, 255, 255);
    background-image:linear-gradient(#da18d0,#a200ff);
    margin-top: 20px;
    padding: 5px 15px;
    font-size: 15px;
   
  }