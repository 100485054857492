
.textCenter{
  border: 1px rgb(228, 228, 228) solid;
    border-top: 5px solid #662e91;
    margin-bottom: 30px;
    border-radius: 3px;
    background-color: #ecdff5;
    height: auto;
}
.img-responsive{
 
  align-items: center;
 
 
}
.valimai{
  font-weight: bolder;
  background-image:linear-gradient(#da189b,#9f2496);
  /* background-image: linear-gradient(#0c25be, #0c25be); */
  background-clip: text;
  color: transparent;
  font-size: 25px;
  margin-top: 20px;
  text-decoration: underline;
  padding-top: 20px;
  text-align: center;
}
.AnDroid{
 
  font-size: 15px ;
 

}
.sensay{
  width: 90%;
  font-size: 15px ;
}
.spank{
  color: #9f2496;
    font-size: 22px;
    font-weight: 600;
}

.burg{
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(#662e91, #662e91);
  margin-top: 20px;
  padding: 5px 15px;
  font-size: 15px;
 
}
.karkka{
  align-items: center;
}
.centerFruit{
  border: 1px rgb(228, 228, 228) solid;
  border-top: 5px solid #662e91;
    background-color: #ecdff5;
  border-radius: 3px;
  margin-top: 30px;
  height: auto;
  padding: 30px;
  
}



.Boomer{

align-items: center;
text-align: center;
margin-bottom: 20px;
}
.name1{
  font-weight: bolder;
  background-image: linear-gradient(#662e91, #662e91);
  background-clip: text;
  color: transparent;
  font-size: 25px;
 
  
  text-decoration: underline;
}
.nesaMani{
  margin-bottom: 10px;
}
.billa{
color: #662e91 !important;
  text-align: left;
  padding-top: 10px;
  font-weight: 00;
  font-family: 'Hind Madurai', sans-serif;
  width: 155px;
  font-size: 18px !important;
  /* font-weight: 600; */
}
.kuruvi{
  padding-top: 10px;
  width: 25px;
  text-align: left;
  font-size: 15px;
}
.alan{
  text-align: left;
  padding-top: 10px;
  margin-bottom: 30px;
  /* font-weight: bold; */
  background-image:linear-gradient(#000000,#000000);
  background-clip: text;
  color: #e9018b !important;
  font-family: 'Hind Madurai', sans-serif;
  margin-left: 10px;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.billa1{
color: #662e91;
  text-align: left;
  padding-top: 13px;
  font-family: 'Hind Madurai', sans-serif;
  width: 170px;
  font-size: 18px !important;
  }
  .kuruvi1{
    padding-top: 13px;
    width: 29px;
    text-align: center;
    font-size: 15px;
  }
  .alan1{
    text-align: left;
    padding-top: 13px;
    margin-bottom: 30px;
    /* font-weight: bold; */
    background-image:linear-gradient(#000000,#000000);
    background-clip: text;
    color: #e9018b;
    font-family: 'Hind Madurai', sans-serif;
    margin-left: 10px;
    font-size: 16px;
      font-weight: 500 !important;
  }
.theri{
  border: none;
background-image: linear-gradient(#662e91, #1e34aa);
  color: #ffffff;
  padding: 6px 20px;
  margin-left: auto;
  font-size: 15px;
  margin-top: 4%;
}
.kuselan{
  border: none;
  background-image:linear-gradient(#da189b,#9f2496);
  /* background-image: linear-gradient(red, red); */
  color: #ffffff;
  padding: 6px 20px;
  font-size: 16px;
  float: right;
  font-weight: 700;
}



.close {
  /* position: absolute;
  left: 25%;
  right: 25%;
  top: 40%;
  bottom: 25%;
  margin: auto;
  width: 6rem;
  height: 2rem;
  border: none;
  border-radius: 3px;
  background-color: salmon;
  color: white;
  font-weight: bolder;
  outline: none; */

  position: absolute;
    left: 25%;
    right: 25%;
    top: 40%;
    bottom: 25%;
    margin: auto;
    width: 8rem;
    height: 4rem;
    border: none;
    border-radius: 10px;
    background-color: salmon !important;
    color: white;
    font-weight: bolder;
    outline: none;
    margin-top: 30px !important;
    opacity: unset !important;
}

.close:hover{
  color:red !important;
}

@media screen and (max-width: 767px) {
.centerFruit {
    padding: 4px !important;

  }
  .billa{
      font-size: 14px !important;
    }
    .alan {
      font-size: 14px !important;
  }
  .billa1 {
    font-size: 14px !important;
}
.alan1 {
  font-size: 14px !important;
}
}


@media only screen and (max-width: 600px) {
.spank {
    font-size: 13px !important;
  }
.valimai {
  font-size: 13px;
}
.kuselan {
  margin-top: 6px;
}
.frog{
  margin-top: 12px;
}
}