.pizza {
  border: 1px rgb(228, 228, 228) solid;
border-top: 5px solid #662e91;
  margin-bottom: 30px;
  border-radius: 3px;
  background-color: #ecdff5;
  height: auto;
  /* padding-bottom: 30px; */
  padding-bottom: 6%;
}

.dontdon {
  width: 100%;
  margin-left: 0px;
}

.donzz {
  width: 70% !important;
}
.viewphotoz{
  width: 70%;
  height: 300px;
}
@media(max-width:767px) {
  .viewphotoz{
  width: 50%;
  height: 205px;
  margin-bottom: 15px;
}
  }
.image-gallery-image{
/* height: 260px !important;
    width: 100% !important; */
    height: 100% !important;
      width: 55% !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 50px;
  width: 34px;
}

.image-gallery-play-button .image-gallery-svg {
  height: 25px;
  width: 28px;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  padding: 0px;
}

.fullscreen{
  font-size: 15px;
    text-align: center;
    font-weight: 600;
    margin-top: 5px;
    color: red;
}

.play-button {
  bottom: 0;
  padding: 0px;
}

.allu {
  height: 300px;
  text-align: center;
  align-items: center;
}

.water {
  font-weight: bolder;
  background-image: linear-gradient(#da189b, #9f2496);
  background-clip: text;
  color: transparent;
  font-size: 24px;
  margin-top: 20px;
  text-decoration: underline;
  padding-top: 12px;
  text-align: center;
}

.AnDroid {

  font-size: 15px;


}

.sans {
  width: 90%;
  font-size: 15px;
}

.spank {
  color: #9f2496 !important;
    font-size: 24px !important;
    font-weight: 700 !important;

}

.burg {
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  color: rgb(255, 255, 255);
  /* background-image: linear-gradient(#2fda18, #249f2e); */
  background-image: linear-gradient(#da189b, #9f2496) !important;
  margin-top: 20px;
  padding: 5px 15px;
  font-size: 15px;
  margin-bottom: 20px;

}

.burg:hover {
  color: #ffffff;
  background-image: linear-gradient(#da189b, #9f2496);
}

.treat {
  align-items: center;
}

.centerFruit {
  border: 1px rgb(228, 228, 228) solid;
  border-top: 5px solid #662e91;
    background-color: #ecdff5;
  border-radius: 3px;
  margin-top: 30px;
  height: auto;
  padding: 30px;

}

.Boomer {

  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.name1 {
  font-weight: bolder;
  /* background-image: linear-gradient(#da189b, #9f2496); */
  background-image: linear-gradient(#da189b, #9f2496) !important;
  background-clip: text;
  color: transparent;
  font-size: 25px !important;


  text-decoration: underline;
}

.nesaMani {
  margin-bottom: 10px;
}

.billa {
  color: #000;
  text-align: left;
  padding-top: 10px;
  font-weight: 00;
  font-family: 'Hind Madurai', sans-serif;
  width: 170px;
  font-size: 15px;
}

.kuruvi {
  padding-top: 10px;
  width: 25px;
  text-align: left;
  font-size: 15px;
}

.alan {
  text-align: left;
  padding-top: 10px;
  margin-bottom: 30px;
  /* font-weight: bold; */
  background-image: linear-gradient(#000000, #000000);
  background-clip: text;
  color: transparent;
  font-family: 'Hind Madurai', sans-serif;
  margin-left: 10px;
  font-size: 16px;
    font-weight: 500 !important;
}

.billa1 {
  color: #000;
  text-align: left;
  padding-top: 13px;
  font-weight: 00;
  font-family: 'Hind Madurai', sans-serif;
  width: 170px;
  font-size: 15px;
}

.kuruvi1 {
  padding-top: 13px;
  width: 25px;
  text-align: left;
  font-size: 15px;
}

.alan1 {
  text-align: left;
  padding-top: 13px;
  margin-bottom: 30px;
  /* font-weight: bold; */
  background-image: linear-gradient(#000000, #000000);
  background-clip: text;
  color: transparent;
  font-family: 'Hind Madurai', sans-serif;
  margin-left: 10px;
  font-size: 16px;
    font-weight: 500 !important;
}

.theri {
  border: none;
  background-image: linear-gradient(#da189b, #9f2496) !important;
  /* background-image: linear-gradient(#0a22a4, #0a22a4) !important; */
  color: #ffffff;
  width: 190px;
  height: 30px;
  font-size: 15px;


}

.theri:hover {
  color: rgb(255, 255, 255);
  background-image: linear-gradient(red,#60fb73 );

}

.kuselan {
  border: none;
  background-image: linear-gradient(#da189b, #9f2496);
  color: #ffffff;
  width: 190px;
  height: 34px;
  float: left;
  font-size: 15px;
}


.App {
  font-family: sans-serif;
  text-align: center;
}

.App>h1 {
  color: #34495e;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
}

.popup_inner {
  position: absolute;
  left: 30%;
  right: 30%;
  top: 25%;
  bottom: 25%;
  height: 250px;
  padding-top: 30px;
  margin: auto;
  background: white;
  border-radius: 20px;
}

.popup_inner>h2 {

  /* margin: auto; */
  color: #34495e;
  font-size: 20px;
}

.close {
  margin-top: 20px !important;
  margin: auto;
  width: 6rem;
  height: 2rem;
  border: none;
  border-radius: 3px;
  background-color: salmon;
  color: white;
  font-weight: bolder;
  outline: none;
}

.btclose {
margin-top: 20px !important;
  margin: auto;
  width: 10rem;
  height: 4rem;
  border: none;
  border-radius: 10px;
  background-color: salmon;
  color: white;
  font-weight: bolder;
  outline: none;
  margin-right: 10px;
  font-size: 20px;
}

.Toastify__toast-body{
  font-size: 20px !important;
  color: red !important;
 
}


.remove {
  border-radius: 5px;
  border: none;
  /* margin-left: 15px; */
  color: rgb(255, 255, 255);
  background-image: linear-gradient(#da2518, #9f2c24);
  margin-top: 5px;
  padding: 5px 15px;
  font-size: 15px;

}

.remove:hover {
  color: #ffffff;
  background-image: #187700;
}





.drink {
  border-radius: 5px;
  border: none;
  margin-left: 15px;
  color: rgb(255, 255, 255);
  background-image: linear-gradient(#2fda18, #249f2e);
  margin-top: 20px;
  padding: 5px 15px;
  font-size: 15px;

}

.drink:hover {
  color: #ffffff;
  background-image: #187700;
}

@media(max-width:768px) {
  .donzz {
    width: 100% !important;
  }
}

@media(max-width:767px) {
  .popup_inner {
    left: 5%;
    right: 5%;
  }
    .btclose {
      margin-top: 20px !important;
      margin: auto;
      width: 9rem;
      height: 3rem;
      border: none;
      border-radius: 10px;
      background-color: salmon;
      color: white;
      font-weight: bolder;
      outline: none;
      margin-right: 10px;
      font-size: 17px;
    }
        .popup_inner>h2 {
          /* margin: auto; */
          color: #34495e;
          font-size: 16px;
        }
  
}
