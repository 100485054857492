.sidebar {
  /* margin: 0; */
  padding: 0;
  width: 200px;
  background-color: rgba(255, 255, 255, 0.727);
  margin-top: 30px;
 
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 98.5%;

}

.sidebar a {
  display: block;
  color: black;
  padding: 16px;
  text-decoration: none;
  font-size: 15px;
  font-weight: 900;
}
 
.sidebar a.active {
  background-color: #662e91;
  /* background-color: red; */
  color: white;
}
.dopp{
  margin-top: 10px !important;
}

.sidebar a:hover:not(.active) {
   background-color: #662e91; 
  /* background-color: red; */
  color: white;
}
.contents{
  margin-top: 60px;
}

/* div.contents {
  margin-left: 200px;
  padding: 1px 16px;
  
} */



@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {float: left;}
  div.contents {margin-left: 0;}
}

@media screen and (max-width: 400px) {
  .sidebar a {
    text-align: center;
    float: none;
  }
}