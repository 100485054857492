.container {
    margin: auto !important;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', sans-serif;
}

.MuiPagination-ul {
    justify-content: center !important; 
    margin-top: 30px !important;
}

.MuiPaginationItem-textPrimary.Mui-selected {
    color: #fff !important;
    background-color: #e9018b !important;
}

.MuiPaginationItem-root {
    font-size: 13px !important;
    /* letter-spacing: 0.01071em; */
}

.MuiPaginationItem-page {
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #e9018b !important;
    font-size: 18px !important;
}

.MuiPaginationItem-icon {
    margin: 0 -8px;
    font-size: 20px !important;
}
.list-inline>li {
    display: inline-block;
    padding-right: 2px !important;
    padding-left: 5px;
}